// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-error-js": () => import("./../../../src/pages/error.js" /* webpackChunkName: "component---src-pages-error-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lb-error-js": () => import("./../../../src/pages/lb/error.js" /* webpackChunkName: "component---src-pages-lb-error-js" */),
  "component---src-pages-lb-index-js": () => import("./../../../src/pages/lb/index.js" /* webpackChunkName: "component---src-pages-lb-index-js" */),
  "component---src-pages-lb-kontakt-js": () => import("./../../../src/pages/lb/kontakt.js" /* webpackChunkName: "component---src-pages-lb-kontakt-js" */),
  "component---src-pages-lb-maintenance-js": () => import("./../../../src/pages/lb/maintenance.js" /* webpackChunkName: "component---src-pages-lb-maintenance-js" */),
  "component---src-pages-lb-portfolio-js": () => import("./../../../src/pages/lb/portfolio.js" /* webpackChunkName: "component---src-pages-lb-portfolio-js" */),
  "component---src-pages-maintenance-js": () => import("./../../../src/pages/maintenance.js" /* webpackChunkName: "component---src-pages-maintenance-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */)
}

